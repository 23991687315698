import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Header from './components/Header/Header.tsx';
import AboutPage from './components/Pages/About/About.tsx';
import CategoryPage from './components/Pages/CategoryPage.tsx'; // Import the new CategoryPage component

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <main>
      <Header />
        <Routes>
          <Route path="/" element={<AboutPage />} />
          <Route path="/category/:category" element={<CategoryPage />} />
          {/* Add other routes here if needed */}
        </Routes>
      </main>
    </Router>
  </React.StrictMode>
);

reportWebVitals();