import React, { useEffect } from 'react';
import SlideShow from './SlideShow.tsx';
import SocialIcons from '../../Header/SocialIcons.tsx';
import CompaniesWidget from './Companies/CompaniesWidget.tsx';
import StayInTouch from '../../StayInTouch.tsx';
import AOS from 'aos';
import 'aos/dist/aos.css';

const AboutPage = () => {
  useEffect(() => {
    AOS.init({ duration: 800, once: true });
  }, []);

  return (
    <div className="about-page z-0">
      <div id="home" className="about-page__content home">
        {/* Image Container with Overlay and Logo */}
        <div className="about-page__photo about-row relative">
          {/* Background Image */}
          <img
            src="/images/ayan-self.webp"
            alt="Ayan Mansuri"
            className="about-page__image object-cover w-full h-full"
          />

          {/* Black Overlay */}
          {/* <div className="absolute inset-0 bg-black opacity-30"></div> */}

          {/* Centered Logo */}
          {/* <div className="absolute inset-0 flex items-center justify-center">
            <img
              src="/images/logo.png"
              alt="Logo"
              className="w-40 h-40 sm:w-32 sm:h-32" // Adjust size as needed
            />
          </div> */}
        </div>

        {/* Text Content */}
        <div className="about-page__text about-row">
          <p className="about-para">
            I'm RJ Ayan Mansuri, your Master of Ceremony. For the past seven years, I've been turning moments into memories with my anchoring.
          </p>
          <br />
          <h2 className="tagline">
            “I don't anchor events,
            I anchor emotions”
          </h2>
          <br />
          <p className="about-para">
            Whether it's connecting with diverse audiences or orchestrating live shows, I bring energy, professionalism, and a seamless flow to every event. It's all about making sure your event isn't just remembered—it's unforgettable.
          </p>
        </div>
      </div>

      {/* Services Section */}
      <div id="services" className="about-row services">
        <SlideShow />
      </div>

      {/* Clients Section */}
      <div id="clients" className="about-row clients">
        <CompaniesWidget />
      </div>

      {/* Newsletter Section */}
      <div id="contact" className="about-row contact">
        <StayInTouch />
      </div>

      {/* Footer with Social Icons and Credits */}
      <div className="about-row flex flex-col items-center space-y-2 py-4 text-gray-300">
        <div className="flex space-x-7">
          <SocialIcons iconSize="w-4 h-4" />
        </div>
        <span className="text-sm font-light py-3">
          © All rights reserved |{' '}
          <a href="https://linkedin.com/in/creatorsky" className="underline">
            Akash Parmar
          </a>
        </span>
      </div>
    </div>
  );
};

export default AboutPage;
