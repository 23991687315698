"use client"

import React, { useState } from 'react';
import emailjs from '@emailjs/browser';

export default function Component() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [status, setStatus] = useState('idle');

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    
    setStatus('sending');

    const templateParams = {
      name: name,
      email: email,
      phone: phone,
      message: message,
    };

    emailjs
      .send(
        'service_wkrm1hh',
        'template_zvnvw1d',
        templateParams,
        'bhXLKa-I06jVxQuQV'
      )
      .then(
        (result) => {
          console.log('Email successfully sent!', result.text);
          setName('');
          setEmail('');
          setPhone('');
          setMessage('');
          setStatus('sent');
          setTimeout(() => setStatus('idle'), 3000);
        },
        (error) => {
          console.error('There was an error sending the email:', error.text);
          setStatus('error');
          setTimeout(() => setStatus('idle'), 3000);
        }
      );
  };

  return (
    <div className="relative h-auto w-full overflow-hidden py-10 md:py-20">
      <div
        className="absolute inset-0 bg-cover bg-center bg-no-repeat"
        style={{
          backgroundImage: "url('/images/stayintouch.jpg')"
        }}
      />
      <div className="absolute inset-0 bg-black opacity-60" />
      <div className="stay-in-touch relative z-10 flex flex-col items-center justify-center h-full px-4">
        <div className="max-w-6xl mx-auto p-6 lg:p-10 text-white">
          <div className="flex flex-col md:flex-row gap-8 text-left contact-container">
            <div className="md:w-1/2 contact-header">
              <h2 className="mb-4 text-left">Contact</h2>
              <p className="mb-3">
                <span className='lg:text-sm'>Use the form to inquire about rates and availability, or just to say hi.</span>
              </p>
              <div className="mb-2">
                <strong className='lg:text-sm'>Phone</strong>
                <p><span className='lg:text-sm'>+91 9106622656</span></p>
              </div>
              <div className="">
                <strong className='lg:text-sm'>Email</strong>
                <p>
                  <a href="mailto:contact@example.com" className="underline lg:text-sm">
                    contact.ayanmansuri@gmail.com
                  </a>
                </p>
              </div>
            </div>
            <div className="md:w-1/2">
              <form onSubmit={handleSubmit} className="contact-us">
                <input
                  type="text"
                  name="name"
                  placeholder="Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="w-full p-3 border-t border-l border-r rounded-none border-gray-400 focus:outline-none placeholder-gray-500 placeholder-opacity-50 bg-transparent text-white"
                  required
                />
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full p-3 border-t border-l border-r rounded-none border-gray-400 focus:outline-none placeholder-gray-500 placeholder-opacity-50 bg-transparent text-white"
                  required
                />
                <input
                  type="tel"
                  name="phone"
                  placeholder="Phone"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  className="w-full p-3 border-t border-l border-r rounded-none border-gray-400 focus:outline-none placeholder-gray-500 placeholder-opacity-50 bg-transparent text-white"
                  required
                />
                <textarea
                  name="message"
                  placeholder="Message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  className="w-full p-3 border-t border-l border-r border-b rounded-none border-gray-400 focus:outline-none placeholder-gray-500 placeholder-opacity-50 bg-transparent text-white"
                  rows={6}
                  required
                ></textarea>
                <div className="mt-4">
                  <button
                    type="submit"
                    className="px-6 py-4 border border-gray-400 text-white hover:bg-gray-100 hover:text-gray-900 transition-colors text-sm"
                    disabled={status === 'sending'}
                  >
                    {status === 'idle' && 'Send Message'}
                    {status === 'sending' && 'Sending...'}
                    {status === 'sent' && 'Sent!'}
                    {status === 'error' && 'Send Again'}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}