import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ImageModal from './ImageModal.tsx';

const ImageCard = ({ url, index, displayName, height, onClick }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  const handleLoad = () => setIsLoading(false);
  const handleError = () => {
    setIsLoading(false);
    setHasError(true);
  };

  return (
    <div
      className="relative overflow-hidden break-inside-avoid cursor-pointer"
      style={{ height: `${height}px` }}
      onClick={() => onClick(index)}
    >
      {isLoading && (
        <div className="absolute inset-0 animate-pulse">
          <div className="w-full h-full" />
        </div>
      )}
      {!hasError ? (
        <div className="absolute inset-0 mb-2">
          <img
            src={url}
            alt={`${displayName} ${index + 1}`}
            className={`w-full h-full object-cover transition-transform duration-300 hover:scale-105 ${isLoading ? 'opacity-0' : 'opacity-100'
              }`}
            style={{ transition: 'opacity 200ms ease-out' }}
            onLoad={handleLoad}
            onError={handleError}
            loading="lazy"
          />
          <div className="absolute inset-0 bg-gradient-to-t from-white/40 to-white/40 opacity-0 hover:opacity-100 transition-opacity duration-300" />
        </div>
      ) : (
        <div className="absolute inset-0 flex flex-col items-center justify-center text-gray-500">
          <svg
            className="w-8 h-8 mb-2"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
            />
          </svg>
          <span className="text-sm">Failed to load</span>
        </div>
      )}
    </div>
  );
};

export default function CategoryPage() {
  const { category } = useParams();
  const [imageUrls, setImageUrls] = useState([]);
  const [isMobile, setIsMobile] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const categoryFolderMap = {
    "Game Jock": "game-jock",
    "Sangeet Sandhya": "sangeet-sandhya",
    "Haldi & Mehndi": "haldi-mehndi",
    "Baarat on Wheels": "baarat-on-wheels",
    "Inauguration & Launch": "inauguration-launch",
    "Corporate Shows & Awards": "corporate-shows-awards",
    "Get together & Parties": "get-together-parties",
    "Poetry Show": "poetry-show",
  };

  const reverseMap = Object.entries(categoryFolderMap).reduce(
    (acc, [display, folder]) => {
      acc[folder] = display;
      return acc;
    },
    {}
  );

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const getRandomHeight = (imageName, isMobile) => {
    const heights = isMobile ? [200, 250, 300, 350] : [200, 250, 300, 350, 400, 450];
    const seed = imageName
      .split('')
      .reduce((acc, char) => acc + char.charCodeAt(0), 0);
    const index = seed % heights.length;
    return heights[index];
  };

  const getDisplayName = (urlCategory) => {
    const decodedCategory = decodeURIComponent(urlCategory);
    return reverseMap[decodedCategory] || decodedCategory;
  };

  const handleImageClick = (index) => {
    setSelectedImageIndex(index);
    document.body.style.overflow = 'hidden';
  };

  const handleCloseModal = () => {
    setSelectedImageIndex(null);
    document.body.style.overflow = 'unset';
  };

    useEffect(() => {
      // Scroll to the top when the component mounts
      window.scrollTo(0, 0);
    }, []);

  useEffect(() => {
    if (!category) return;

    const loadImages = async () => {
      setIsLoading(true);
      try {
        const folderName =
          categoryFolderMap[getDisplayName(category)] ||
          category
            .toLowerCase()
            .replace(/ & /g, '-')
            .replace(/[^a-z0-9-]/g, '-');

        const response = await fetch(
          `/images/cat/${folderName}/manifest.json`
        );
        if (!response.ok) throw new Error('Failed to load image manifest');

        const manifest = await response.json();
        const images = manifest.images.map(
          (imageName) => `/images/cat/${folderName}/${imageName}`
        );

        setImageUrls(images);
      } catch (error) {
        console.error('Error loading images:', error);
        setImageUrls([]);
      } finally {
        setIsLoading(false);
      }
    };

    loadImages();
  }, [category]);

  if (!category) {
    return <div>Category not found</div>;
  }

  const displayName = getDisplayName(category);

  const SkeletonGrid = () => (
    <div className="columns-2 md:columns-3 lg:columns-4 gap-2">
      {[...Array(12)].map((_, index) => (
        <div
          key={index}
          className="relative bg-gray-300 animate-pulse"
          style={{ height: `${250 + index * 10}px`, marginBottom: '8px' }}
        />
      ))}
    </div>
  );

  return (
    <div className={`w-full bg-gray-100 lg:p-6 ${imageUrls.length === 0 ? "py-60 text-center overscroll-none min-h-[100vh]" : "min-h-screen"}`}>
      {isLoading ? (
        <SkeletonGrid />
      ) : (
        <>
          {imageUrls.length === 0 && (
            <div className="text-gray-700 px-4 py-3 rounded mb-4">
              Coming Soon...
            </div>
          )}

          <div className="columns-2 md:columns-3 lg:columns-4 gap-2">
            {imageUrls.map((url, index) => (
              <ImageCard
                key={url}
                url={url}
                index={index}
                displayName={displayName}
                height={getRandomHeight(url, isMobile)}
                onClick={handleImageClick}
              />
            ))}
          </div>
        </>
      )}

      {selectedImageIndex !== null && (
        <ImageModal
          imageUrl={imageUrls[selectedImageIndex]}
          altText={`${displayName} ${selectedImageIndex + 1}`}
          onClose={handleCloseModal}
        />
      )}
    </div>
  );
}
