import React from 'react';
import { X } from 'lucide-react';

interface ImageModalProps {
  imageUrl: string;
  altText: string;
  onClose: () => void;
}

const ImageModal = ({ imageUrl, altText, onClose }: ImageModalProps) => {
  return (
    <div className="fixed inset-0 z-50 bg-white">
      {/* Header with close button */}
      <div className="absolute top-0 right-0 p-4">
        <button
          onClick={onClose}
          className="p-2 hover:bg-gray-100 rounded-full"
          aria-label="Close modal"
        >
          <X className="h-6 w-6 text-gray-400" />
        </button>
      </div>

      {/* Image container with navigation arrows */}
      <div className="h-full w-full flex items-center justify-between">
        {/* <button className="text-gray-400 hover:text-gray-600" aria-label="Previous image">
          <svg className="h-8 w-8" viewBox="0 0 24 24" fill="none" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
          </svg>
        </button> */}
        
        <div className="flex-1 flex justify-center">
          <img
            src={imageUrl}
            alt={altText}
            className="lg:max-h-[90vh] lg:max-w-[80vw] object-contain"
          />
        </div>

        {/* <button className="text-gray-400 hover:text-gray-600" aria-label="Next image">
          <svg className="h-8 w-8" viewBox="0 0 24 24" fill="none" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
          </svg>
        </button> */}
      </div>
    </div>
  );
};

export default ImageModal;