import React, { useState, useEffect, useRef } from 'react'
import { motion } from 'framer-motion'

interface Pin {
  id: string
  src: string
}

const companyImages: Pin[] = [
  { id: 'ttec', src: '/images/companies/ttec.jpeg' },
  { id: 'vivanta', src: '/images/companies/vivanta.png' },
  { id: 'love104fm', src: '/images/companies/love104fm.jpg' },
  { id: 'jollyhires', src: '/images/companies/jollyhires.jpg' },
  { id: 'hlcollege', src: '/images/companies/hlcollege.jpg' },
  { id: 'thefern', src: '/images/companies/thefern.jpg' },
  { id: 'heartymart', src: '/images/companies/heartymart.png' },
  { id: 'radiomirchi', src: '/images/companies/radiomirchi.jpg' },
  { id: 'sparknfizz', src: '/images/companies/sparknfizz.png' },
  { id: 'honda', src: '/images/companies/honda.png' },
  { id: '95radioone', src: '/images/companies/95radioone.png' },
  { id: 'jalso', src: '/images/companies/jalso.jpg' },
  { id: 'ananta', src: '/images/companies/ananta.png' },
  { id: 'shankus', src: '/images/companies/shankus.png' },
  { id: 'ainakwala', src: '/images/companies/ainakwala.jpg' },
  { id: 'myfm', src: '/images/companies/myfm.jpg' },
  { id: 'mihir', src: '/images/companies/mihir.png' },
  { id: 'pallash', src: '/images/companies/pallash.jpg' },
  { id: 'spai', src: '/images/companies/spai.jpg' },
]

interface HorizontalPinterestGridProps {
  componentHeight?: string
  imageHeight?: number
}

export default function HorizontalPinterestGrid({ 
  componentHeight = "100%",
  imageHeight = 90
}: HorizontalPinterestGridProps = {}) {
  const [shouldScroll, setShouldScroll] = useState(false)
  const containerRef = useRef<HTMLDivElement>(null)
  const contentRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const checkOverflow = () => {
      if (containerRef.current && contentRef.current) {
        setShouldScroll(contentRef.current.scrollWidth > containerRef.current.clientWidth)
      }
    }

    checkOverflow()
    window.addEventListener('resize', checkOverflow)
    return () => window.removeEventListener('resize', checkOverflow)
  }, [])

  const rows = [
    companyImages.slice(0, 7),
    companyImages.slice(7, 14),
    companyImages.slice(14, 21)
  ]

  return (
    <div 
      ref={containerRef}
      className="overflow-hidden w-full pt-10"
      style={{ height: componentHeight }}
    >
        <h3 className="mb-10">Brands Served</h3>
      <style>{`
        @keyframes scroll {
          0% {
            transform: translateX(0);
          }
          100% {
            transform: translateX(-50%);
          }
        }
        .scrolling-wrapper {
          animation: ${shouldScroll ? 'scroll 30s linear infinite' : 'none'};
        }
      `}</style>
      <div 
        ref={contentRef}
        className="inline-flex flex-col space-y-2 p-2 scrolling-wrapper"
      >
        {rows.map((row, rowIndex) => (
          <React.Fragment key={rowIndex}>
            <div className="flex space-x-3">
              {[...row].map((pin, index) => (
                <motion.div
                  key={`${pin.id}-${index}`}
                  className="flex-shrink-0"
                  style={{
                    width: `${imageHeight}px`,
                    height: `${imageHeight}px`,
                  }}
                  initial={{ opacity: 0, scale: 0.8 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.5 }}
                >
                  <img
                    src={pin.src}
                    alt={`Company ${pin.id}`}
                    className="w-full h-full object-contain rounded-lg mx-0"
                    // style={{ filter: 'grayscale(100%) sepia(50%) hue-rotate(180deg)' }}
                  />
                </motion.div>
              ))}
            </div>
          </React.Fragment>
        ))}
      </div>
    </div>
  )
}