import React, { useState, useEffect } from 'react'
import { X, Menu } from 'lucide-react'
import SocialIcons from './SocialIcons.tsx'
import NavigationItems from './NavigationItems.tsx'
import HeadRoom from 'react-headroom'
import { useNavigate, useLocation } from 'react-router-dom'

const Header: React.FC = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)
  const [isMobile, setIsMobile] = useState(false)
  const [isScrolled, setIsScrolled] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()



  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768)
    const handleScroll = () => setIsScrolled(window.scrollY > 100)

    handleResize()
    handleScroll()

    window.addEventListener('resize', handleResize)
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('resize', handleResize)
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const toggleMobileMenu = () => setIsMobileMenuOpen(!isMobileMenuOpen)

  const handleLogoClick = () => {
    const scrollToHome = () => {
      const homeElement = document.getElementById('home')
      if (homeElement) {
        const headerOffset = document.querySelector('header')?.clientHeight || 0
        const elementPosition = homeElement.getBoundingClientRect().top + window.scrollY
        const offsetPosition = elementPosition - headerOffset

        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth'
        })
      }
    }

    if (location.pathname !== '/') {
      navigate('/', { replace: true })
      setTimeout(scrollToHome, 100) // Delay to ensure the home page is loaded
    } else {
      scrollToHome()
    }
  }
  return (
    <>
      {isMobile ? (
        <HeadRoom style={{ zIndex: 40 }}>
          <header className="relative z-50 bg-white shadow-md">
            <div className="flex justify-between items-center py-4 px-5">
              {/* Logo and Subtitle Container */}
              <div className="flex flex-col items-start">
                <h1
                  className="logo text-xl font-bold m-0 p-0 cursor-pointer"
                  onClick={handleLogoClick}
                >
                  RJ AYAN MANSURI
                </h1>
                <h6 className="text-xs font-extralight text-gray-600 mx-auto my-0">
                  Anchor & Host
                </h6>
              </div>
              {/* Menu Button */}
              <button
                onClick={toggleMobileMenu}
                className="text-xl z-50 relative hover:text-gray-800 transition-colors duration-200"
                aria-label="Toggle Mobile Menu"
              >
                {isMobileMenuOpen ? (
                  <X color="#555555" />
                ) : (
                  <Menu color="#555555" />
                )}
              </button>
            </div>
          </header>
        </HeadRoom>
      ) : (
        <header className="relative z-50 bg-white">
          <div className="flex flex-col items-center py-8 px-6">
            <h1
              className="logo text-2xl font-bold cursor-pointer"
              onClick={handleLogoClick}
            >
              RJ AYAN MANSURI
            </h1>
            <h6 className="text-sm font-extralight text-gray-600 md:mb-10">
              Anchor & Host
            </h6>
            <DesktopNavigation isScrolled={isScrolled} />
          </div>
        </header>
      )}
      {isMobile && (
        <MobileNavigation isOpen={isMobileMenuOpen} onClose={toggleMobileMenu} />
      )}
    </>
  );
  
}

const DesktopNavigation: React.FC<{ isScrolled: boolean }> = ({ isScrolled }) => (
  <nav className="flex flex-col items-center w-full">
    <div className={`nav-items w-full ${isScrolled ? 'fixed top-0 left-0 right-0 bg-white py-4 z-50' : ''}`}>
      <NavigationItems 
        className="flex justify-center space-x-10" 
        itemClassName="text-sm font-extralight menu-item" 
        orientation="horizontal"
      />
    </div>
    <div className={`flex items-center space-x-5 text-gray-300 mt-8 ${isScrolled ? 'mt-20' : ''}`}>
      <SocialIcons iconSize="w-4 h-4" />
    </div>
  </nav>
)

const MobileNavigation: React.FC<{ isOpen: boolean, onClose: () => void }> = ({ isOpen, onClose }) => (
  <div 
    className={`fixed inset-0 overflow-y-auto transition-opacity duration-300 ease-out ${
      isOpen ? 'opacity-100 pointer-events-auto touch-none' : 'opacity-0 pointer-events-none'
    }`}
    style={{
      backgroundColor: 'white',
      zIndex: 40,
      marginTop: '70px', // Adjust this value to match your header height
    }}
  >
    <div className="bg-white min-h-screen">
      <nav className="flex flex-col items-center space-y-4 py-4">
        <NavigationItems 
          itemClassName="text-base font-extralight hover:underline menu-item block py-2" 
          orientation="vertical"
          onClose={onClose}
        />
      </nav>
      <div className="flex justify-center space-x-6 py-4 text-gray-300">
        <SocialIcons iconSize="w-4 h-4" />
      </div>
    </div>
  </div>
)

export default Header