// NavigationItems.tsx
import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

interface NavigationItemsProps {
  className?: string
  itemClassName?: string
  orientation?: 'horizontal' | 'vertical'
  onClose?: () => void
}

const NavigationItems: React.FC<NavigationItemsProps> = ({ className, itemClassName, orientation = 'horizontal', onClose }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const menuItems = [
    { name: 'HOME', id: 'home' },
    { name: 'SERVICES', id: 'services' },
    { name: 'CLIENTS', id: 'clients' },
    { name: 'CONTACT', id: 'contact' }
  ]

  const handleClick = (id: string) => {
    const scrollToSection = () => {
      const element = document.getElementById(id)
      if (element) {
        const headerOffset = 20
        const elementPosition = element.getBoundingClientRect().top + window.scrollY
        const offsetPosition = elementPosition - headerOffset

        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth'
        })
      }
      if (onClose) {
        onClose()
      }
    }

    if (location.pathname !== '/') {
      navigate('/', { replace: true })
      setTimeout(scrollToSection, 100) // Delay to ensure the home page is loaded
    } else {
      scrollToSection()
    }
  }

  return (
    <div className={`${className} flex ${orientation === 'vertical' ? 'flex-col items-center' : 'flex-row justify-center'}`}>
      {menuItems.map((item) => (
        <button
          key={item.id}
          onClick={() => handleClick(item.id)}
          className={`${itemClassName} ${orientation === 'vertical' ? 'block text-center' : 'inline-block'}`}
        >
          {item.name}
        </button>
      ))}
    </div>
  )
}

export default NavigationItems